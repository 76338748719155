import React, { Component, } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import Lottie from 'react-lottie';
import QRCode from 'qrcode.react';
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';
import "animate.css";
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

//import bgAnim from 'animations/bg_horizontal.js';
import bgAnimGif from 'animations/bg_horizontal.gif';
import tickCard from 'animations/tickCard.js';
import plusTwo from "animations/plusTwo.js";
//import round1 from "animations/titles/round1.js";
//import round2 from "animations/titles/round2.js";
//import round3 from "animations/titles/round3.js";
//import round4 from "animations/titles/round4.js";
//import round5 from "animations/titles/round5.js";
//import round6 from "animations/titles/round6.js";
//import round7 from "animations/titles/round7.js";
//import round8 from "animations/titles/round8.js";
//import winnersAre from "animations/titles/winnersAre.js";
//import theySaid from "animations/titles/theySaid.js";
//import howToPlayAnim from "animations/titles/howToPlay.js";
import timerTurning from "animations/timerTurning.js";
import timerEnd from "animations/timerEnd.js";

import vsImg from 'images/vs.png';
import mutedIcon from "images/squirms_muted.png";
import unmutedIcon from "images/squirms_unmuted.png";
import fullscreenIcon from "images/squirms_fullscreen.png";
import helpIcon from "images/squirms_help.png";
import squirmsLogo from "images/cos-logo.png";
import bannerImg from "images/banner.png";

import playingBgMusic from "audio/short-bg-music.mp3";
import answerRevealSFX from "audio/answer-reveal.wav";
import awardPointSFX from "audio/award-point.wav";
import hoverOrClickSFX from "audio/hover-click.mp3";
import p1AppearSFX from "audio/p1-appear.wav";
import p2AppearSFX from "audio/p2-appear.wav";
import joinedSFX from "audio/player-joined.wav";
import readySFX from "audio/player-ready.wav";
import agreedSFX from "audio/players-agreed.wav";
import disagreedSFX from "audio/players-disagreed.wav";
import winnerSFX from "audio/winner.wav";
import wrongSFX from "audio/error.wav";
import bannerSFX from "audio/banner-woosh.wav";


import Loading from "components/Loading";
import Menu from "components/Menu";
import Player from "components/Player";
import Tutorial from "components/Tutorial";
import styles from 'components/HomeStyles.module.scss';

import getAvatarById from "constants/avatars";
import ErrorModal from './Utility/ErrorModal';

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Question: "question",
    Answers: "answers",
    Results: "results",
    EndGame: "end_game",
};
const gameId = "can_of_squirms";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);

        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true,
            muted: false,
            menuOpen: false,
            reconnectionToken: "",
            logStreamId: "",

            isGame: false,
            //isGame: true,
            showHeaderFooter: false,
            players: [],
            playersRequired: 3,
            gameState: GameStates.Loading,
            question: null,
            qSize: "large",
            vsPlayers: [],
            isQuestion: false,
            shrinkQuestion: false,
            showAnswers: false,
            timer: "",
            winnerText: "",
            winnerTitle: "",
            showWinners: false,
            playAgain: false,
            roundNumber: 0,
            totalRounds: 0,
            hideNames: false,
            showPlayerOne: false,
            showVs: false,
            showPlayerTwo: false,
            movePosOne: {},
            movePosTwo: {},
            fadeOutContent: false,
            showQuestion: false,
            validateVs: false,
            nextQ: null,
            nextPlayers: [],
            winners: [],
            animData: {},
            timerOptions: timerTurning,
            doingTutorial: false,
            showTutorial: false,
            maxVoteCount: 0,
            skipTutorialCount: 0,
            doPodiums: false,
            showStartGame: false,
            shrinkPotatos: false,
            showBigNames: false,
            movePotOne: false,
            movePotTwo: false,
            gameBegun: false,

            tickedSkipTutorial: false,
            leaderboardPlayers: [],
            doConfetti: false,


            //titles: [
            //    {
            //        options: round1,
            //        context: 1,
            //        isPaused: true,
            //    },
            //    {
            //        options: round2,
            //        context: 2,
            //        isPaused: true,
            //    },
            //    {
            //        options: round3,
            //        context: 3,
            //        isPaused: true,
            //    },
            //    {
            //        options: round4,
            //        context: 4,
            //        isPaused: true,
            //    },
            //    {
            //        options: round5,
            //        context: 5,
            //        isPaused: true,
            //    },
            //    {
            //        options: round6,
            //        context: 6,
            //        isPaused: true,
            //    },
            //    {
            //        options: round7,
            //        context: 7,
            //        isPaused: true,
            //    },
            //    {
            //        options: round8,
            //        context: 8,
            //        isPaused: true,
            //    },
            //    {
            //        options: theySaid,
            //        context: "answers",
            //        isPaused: true,
            //    },
            //    {
            //        options: howToPlayAnim,
            //        context: "tutorial",
            //        isPaused: true,
            //    },
            //    {
            //        options: winnersAre,
            //        context: "winners",
            //        isPaused: true,
            //    }
            //],

            playTitle: false,
            titleText: "",

            gotLocationPing: true,
            connectionIssue: false,

            showStartWarning: false,
            playerConnections: {},
            serverGameState: null,
        };
        this.titleRefs = {
            1: React.createRef(),
            2: React.createRef(),
            3: React.createRef(),
            4: React.createRef(),
            5: React.createRef(),
            6: React.createRef(),
            7: React.createRef(),
            8: React.createRef(),
            "answers": React.createRef(),
            "tutorial": React.createRef(),
            "winners": React.createRef(),
        };

        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

        this.moveInterval = null;

        this.gameBgAudio = new Howl({
            src: playingBgMusic,
            loop: true,
            volume: 0.2,
        });
    }

    componentDidMount() {
        this.setTags();
        this.doReconnect();

        this.toggleMute(true, false);
        this.preloadAudio([
            answerRevealSFX,
            awardPointSFX,
            p1AppearSFX,
            p2AppearSFX,
            hoverOrClickSFX,
            joinedSFX,
            readySFX,
            agreedSFX,
            disagreedSFX,
            winnerSFX,
            wrongSFX,
            bannerSFX
        ]);

        //this.playAudio(playingBgMusic, true, 0.2);
        Howler.volume(0.6);
        this.gameBgAudio.play();

        //window.addEventListener("unload", () => {
        //    if (this.state.room) {
        //        this.state.room.leave(true);
        //    }
        //})
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [ roomId, reconnectToken ] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            if (node) {
                node.classList.add(`${prefix}animated`, animationName);

                // When the animation ends, we clean the classes and resolve the Promise
                function handleAnimationEnd(event) {
                    event.stopPropagation();
                    node.classList.remove(`${prefix}animated`, animationName);
                    resolve('Animation ended');
                }

                node.addEventListener('animationend', handleAnimationEnd, { once: true });
            }
        });

    preloadAudio(audioFiles) {
        for (let i = 0; i < audioFiles.length; i++) {
            let audio = new Howl({
                src: [audioFiles[i]],
                preload: true
            });
        }
    }

    playAudio(audioFile, loop = false, volume = 1) {
        //let audio = new Audio(audioFile);
        //audio.play();
        let audio = new Howl({
            src: [audioFile],
            loop: loop,
            volume: volume,
        });
        audio.play();
    }

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen == true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = this.state.muted == true ? false : true;
        }

        console.log("muted new val : " + newVal)

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    getMovePosition(potatoId, destId) {
        let potato = document.getElementById(potatoId);
        let dest = document.getElementById(destId);

        if (potato) {
            let potatoPos = potato.getBoundingClientRect();
            let destPos = dest.getBoundingClientRect();

            let top;
            let left;

            top = destPos.top - potatoPos.top;
            left = destPos.left - potatoPos.left;

            return { top, left };
        } else {
            return { top: 0, left: 0, };
        }
    }

    doNewQuestion(question, players) {
        let qSize = "4.75vh";
        if (question.question.length <= 40) {
            qSize = "4.75vh";
        } else if (question.question.length <= 70) {
            qSize = "3.75vh";
        } else if (question.question.length <= 100) {
            qSize = "2.75vh";
        } else {
            qSize = "1.75vh";
        }

        this.setState({ fadeOutContent: false, isQuestion: true, hideNames: true, question: question, qSize, gameState: GameStates.Question, });


        setTimeout(() => {
            //this.playAudio(p1AppearSFX, false, 0.6);
            //this.setState({ showPlayerOne: true, });

            
            //setTimeout(() => {
            //    this.playAudio(p2AppearSFX, false, 0.6);
            //    this.setState({ showPlayerTwo: true, });
            //}, 500);

            this.playAudio(p1AppearSFX, false, 0.6);
            //this.movePotato(players[0], "anchorOne_first");
            this.movePotatos(players, "anchorOne_first", "anchorTwo_first");
            this.setState({ vsPlayers: players, showBigNames: true, movePotOne: true, });
            setTimeout(() => {
                this.setState({ showVs: true, });
                setTimeout(() => {
                    this.playAudio(p2AppearSFX, false, 0.6);
                    this.setState({ movePotTwo: true, });
                    //this.movePotato(players[1], "anchorTwo_first");
                    setTimeout(() => {
                        this.moveInterval = setInterval(() => {
                            this.movePotatos(players, "anchorOne_first", "anchorTwo_first");
                        }, 1000);

                        setTimeout(() => {
                            this.setState({ showQuestion: true, });
                            setTimeout(() => {
                                this.setState({ shrinkQuestion: true, });
                                this.state.room.send("start_question", {});
                            }, 1500);
                        }, 1500);
                    }, 1000);
                }, 500);
            }, 500);
        }, 1500);
    }

    movePotatos(vsPlayers, anchorOne, anchorTwo, second = true) {
        let movePosOne = {};
        let movePosTwo = {};

        if (this.state.movePosOne.left) {
            movePosOne = this.state.movePosOne;
            movePosTwo = this.state.movePosTwo;
            let extraValsOne = this.getMovePosition(`potato-${vsPlayers[0].id}`, anchorOne);
            let extraValsTwo = this.getMovePosition(`potato-${vsPlayers[1].id}`, anchorTwo);

            movePosOne.left += extraValsOne.left;
            movePosOne.top += extraValsOne.top;
              
            movePosTwo.left += extraValsTwo.left;
            movePosTwo.top += extraValsTwo.top;
        } else {
            if (vsPlayers[0]) {
                movePosOne = this.getMovePosition(`potato-${vsPlayers[0].id}`, anchorOne);
            }
            if (vsPlayers[1]) {
                movePosTwo = this.getMovePosition(`potato-${vsPlayers[1].id}`, anchorTwo);
            }
        }

        if (second) {
            this.setState({ movePosOne, movePosTwo, });
        } else {
            this.setState({ movePosOne, });
        }
    }

    movePotato(player, anchor, first) {
        let movePos = {};
        let stateMovePos;
        if (first) stateMovePos = this.state.movePosOne;
        else stateMovePos = this.state.movePosTwo;

        if (stateMovePos.left) {
            movePos = stateMovePos;
            let extraVals = this.getMovePosition(`potato-${player.id}`, anchor);

            movePos.left += extraVals.left;
            movePos.top += extraVals.top;
        } else {
            if (player) {
                movePos = this.getMovePosition(`potato-${player.id}`, anchor);
            }
        }

        if (first) {
            this.setState({ movePosOne: movePos, });
        } else {
            this.setState({ movePosTwo: movePos, });
        }
    }

    movePotatosBack() {
        this.hideAllPlayerAnswered();
        this.setState({ vsPlayers: [], movePosOne: {}, movePosTwo: {}, movePotOne: false, movePotTwo: false, });
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id != id), }
        });
    }

    addPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    showPlayerAnswered(id) {
        let players = [...this.state.players];

        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.hasAnswered = true;

        players[pos] = player;

        this.setState({ players });
    }

    hideAllPlayerAnswered() {
        let players = [...this.state.players];
        players.forEach((value, key) => {
            value.hasAnswered = false;
            value.showAnswer = false;
            value.animAnswer = false;
            value.validateAnswer = false;
            value.showBonus = false;
            value.answerText = "";
            value.bonusText = "";
        });
        this.setState({ players, showAnswers: false, validateVs: false, });
    }
    doResults(players, vsPlayers) {
        this.hideAllPlayerAnswered();
        setTimeout(() => {
            this.showPlayersAnswers(players, vsPlayers);
        }, 2000);
    }

    updateTimer(count) {
        if (count <= 10) {
            if (count <= 5) {
                if (count <= 0) {
                    setTimeout(() => {
                        this.setState({ showTimer: false, });
                    }, 2000);
                }
                this.setState({ timerOptions: timerEnd });
            } else {
                this.setState({ timerOptions: timerTurning });
            }
            this.setState({ timer: count, showTimer: true, });
        } else {
            this.setState({ showTimer: false, });
        }
    }

    doStartNextRound(vsPlayers) {
        setTimeout(() => {
            if (this.moveInterval) clearInterval(this.moveInterval);
            this.movePotatosBack();
            this.setState({ fadeOutContent: true, showBigNames: false, });
            setTimeout(() => {
                if (this.state.roundNumber != this.state.totalRounds) {
                    this.setState({ hideNames: false, });
                }
                this.setState({ shrinkQuestion: false, showPlayerOne: false, showQuestion: false, showPlayerTwo: false, showVs: false, shrinkPotatos: false, });
                setTimeout(() => {
                    this.state.room.send("start_round", {});
                }, 2000);
            }, 2000);
        }, 1000);
    }

    showPlayersAnswers(players, vsPlayers) {
        let nonVsPlayers = [];
        for (const [key, value] of Object.entries(players)) {
            if (value.id != vsPlayers[0].id && value.id != vsPlayers[1].id) {
                nonVsPlayers.push(value);
            }
        }
        for (let i = 0; i < nonVsPlayers.length; i++) {
            setTimeout(() => {
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(nonVsPlayers[i].id);
                let player = { ...statePlayers[pos] };
                player.showAnswer = true;
                player.animAnswer = true;
                player.showBonus = true;
                if (nonVsPlayers[i].cosData.answer != "disagree") {
                    player.answerText = this.getNamebyId(nonVsPlayers[i].cosData.answer);
                } else {
                    player.answerText = nonVsPlayers[i].cosData.answer;
                }
                player.bonusText = this.getNamebyId(nonVsPlayers[i].cosData.bonusAnswer);
                statePlayers[pos] = player;
                this.playAudio(readySFX, false, 0.6);
                this.setState({ players: statePlayers });
                if (i == nonVsPlayers.length - 1) {
                    setTimeout(() => {
                        this.setState({ animData: { players, vsPlayers }, });

                        this.doTitleAnim("answers");
                        this.playAudio(answerRevealSFX, false, 0.6);
                        setTimeout(() => {
                            this.state.room.send("update_answers_content");
                        }, 5000);
                    }, 2000);
                }
            }, (i * 600));
        }
        this.setState({ vsPlayers });
    }

    showVsPlayersAnswer(players, vsPlayers) {
        this.setState({ showPlayerOne: true, showPlayerTwo: true, shrinkPotatos: true, });
        if (this.moveInterval) clearInterval(this.moveInterval);
        this.movePotatos(vsPlayers, "anchorOne_second", "anchorTwo_second");
        this.moveInterval = setInterval(() => {
            this.movePotatos(vsPlayers, "anchorOne_second", "anchorTwo_second");
        }, 1000);
        setTimeout(() => {
            this.setState({ showAnswers: true, });
            setTimeout(() => {
                this.validateAnswers(players, vsPlayers);
                this.updateScores(players);
                this.updateTimer(15);
                this.state.room.send("start_ready_timer", {});
            }, 2000);
        }, 2000);
    }

    validateAnswers(players, vsPlayers) {
        if (vsPlayers[0].cosData.answer === vsPlayers[1].cosData.answer) {
            this.playAudio(agreedSFX, false, 0.6);
            this.setState({ validateVs: true, });
        } else {
            this.playAudio(disagreedSFX, false, 0.6);
        }

        setTimeout(() => {
            let count = 0;
            for (const [key, value] of Object.entries(players)) {
                if (key != vsPlayers[0].id && key != vsPlayers[1].id) {
                    setTimeout(() => {
                        let statePlayers = [...this.state.players];
                        let pos = statePlayers.map(function (e) { return e.id; }).indexOf(value.id);
                        let player = { ...statePlayers[pos] };
                        player.validateAnswer = true;
                        player.isCorrect = value.cosData.isCorrect;
                        statePlayers[pos] = player;

                        if (value.cosData.isCorrect) {
                            this.playAudio(awardPointSFX, false, 0.6);
                        } else {
                            this.playAudio(wrongSFX, false, 0.6);
                        }
                        this.setState({ players: statePlayers, });
                    }, (count * 300));
                    count++;
                }
            }
        }, 1500);
    }

    getNamebyId(id) {
        let player = this.state.players.filter(obj => {
            return obj.id === id;
        });
        if (player[0]) {
            return player[0].name;
        } else {
            return "";
        }
    }
    updateScores(players) {
        let statePlayers = [...this.state.players];
        for (const [key, value] of Object.entries(players)) {
            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(value.id);
            let player = { ...statePlayers[pos] };
            player.cosData.score = value.cosData.score;
            statePlayers[pos] = player;
        }
        this.setState({ players: statePlayers, });
    }

    doGameOver(winners, players) {
        //this.setState({ isQuestion: false, });
        this.showWinners(winners, players);
        this.playAudio(winnerSFX, false, 0.6);
        this.getLeaderboardList(players);

        setTimeout(() => {
            this.hideWinners();
            setTimeout(() => {
                this.state.room.send("reached_end", {});
                this.resetPodiums();
                this.showPlayAgain();
            }, 2000);
        }, 8000);
    }

    getLeaderboardList(players) {
        let statePlayers = [...this.state.players];
        statePlayers.forEach(x => {
            x.scrawlData = players[x.id].scrawlData;
        });

        let leaderboardPlayers = [];
        statePlayers.forEach((x, index) => {
            let boardPlayer = {
                name: x.name,
                score: x.cosData.score,
                avatar: x.avatar,
            };
            leaderboardPlayers.push(boardPlayer);
        });
        leaderboardPlayers = leaderboardPlayers.sort((a, b) => {
            return b.score - a.score;
        });

        for (let i = 0; i < leaderboardPlayers.length; i++) {
            leaderboardPlayers[i].position = this.getPosition(leaderboardPlayers[i], i, leaderboardPlayers);
        }

        this.setState({ leaderboardPlayers, });
    }

    getPosition(player, index, leaderboardPlayers) {
        let position = 1;
        if (index > 0) {
            const playerAbove = leaderboardPlayers[index - 1];
            if (playerAbove != null) {
                if (player.score == playerAbove.score) return playerAbove.position;
                else return playerAbove.position + 1;
            }
        }
        return position;
    }

    resetPodiums() {
        let podiums = document.getElementsByName("podium");
        this.setState({ doPodiums: false });
        Array.prototype.filter.call(podiums, function (x) {
            x.style.display = "none";
            //x.parentElement.style.marginTop = "4vh";
            x.parentElement.style.marginTop = null;
        });
    }

    doPodiums(players) {
        let arrPlayers = []

        this.setState({ doPodiums: true });

        for (const [key, value] of Object.entries(players)) {
            arrPlayers.push(value);
        }

        let sorted = arrPlayers.sort((a, b) => (a.cosData.score > b.cosData.score) ? 1 : -1);
        let matchIndex = null;

        sorted.forEach((x, index) => {
            setTimeout(() => {
                let player = document.getElementById(`player-${x.id}`);
                player.querySelector("#podium").style.display = "flex";

                let podiumValue = index;
                if (index != 0) {
                    if (x.cosData.score == sorted[index - 1].cosData.score) {
                        if (matchIndex != null) {
                            podiumValue = matchIndex;
                        } else {
                            matchIndex = index - 1;
                            podiumValue = index - 1;
                        }
                    } else {
                        matchIndex = null;
                    }
                }
                player.style.marginTop = `${player.style.marginTop - (podiumValue * 5.5)}vh`;
            }, (index * 300));
        });
    }

    showWinners(winners, players) {
        this.doPodiums(players);

        setTimeout(() => {
            let winnerText = "";
            let winnerTitle = "";
            if (winners.length > 1) {
                winnerTitle += `The Winners are:`
                for (let i = 0; i < winners.length; i++) {
                    if (i == winners.length - 1) {
                        winnerText += `and ${winners[i].name}!`;
                    } else {
                        winnerText += `${winners[i].name}, `;
                    }
                }
            } else {
                winnerTitle += `The Winner is:`
                winnerText += `${winners[0].name}`;
            }
            this.setState({ winnerText, winnerTitle, showWinners: true, doConfetti: true, });
        }, 3000);
    }

    hideWinners() {
        this.setState({ showWinners: false, });
    }

    showPlayAgain() {
        this.setState({ playAgain: true, });
    }
    hidePlayAgain() {
        this.setState({ playAgain: false, });
    }

    playAgain = (confirm = false) => {
        let allConnected = true;
        for (const [key, value] of Object.entries(this.state.playerConnections)) {
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.hidePlayAgain();
            this.playAudio(hoverOrClickSFX, false, 0.6);
            this.state.room.send("new_game", {});
            this.setState({ showStartGame: false, });
        }
    }

    differentGame() {
        this.hidePlayAgain();
        this.playAudio(hoverOrClickSFX, false, 0.6);
        this.state.room.send("change_game", {});
    }

    resetGame(players) {
        this.hidePlayAgain();
        this.updateScores(players);
        this.resetPodiums();

        this.setState({ vsPlayers: [],});
        setTimeout(() => {
            this.state.room.send("start_round", {});
        }, 3000);
    }

    doSkipRound() {
        this.setState({ gameState: GameStates.Answers, });
        setTimeout(() => {
            if (this.moveInterval) clearInterval(this.moveInterval);
            this.movePotatosBack();
            this.setState({ fadeOutContent: true, });
            setTimeout(() => {
                this.setState({ hideNames: false, shrinkQuestion: false, showPlayerOne: false, showQuestion: false, showPlayerTwo: false, showVs: false, });
                setTimeout(() => {
                    this.state.room.send("start_round", {});
                }, 2000);
            }, 2000);
        }, 1000);
    }


    animatePotato(id, animation) {
        this.animateCSS(`#anim-potato-${id}`, animation);
    }

    updateRoundNums(totalRounds, roundNumber) {
        //if (totalRounds < roundNumber) {
        //    totalRounds = roundNumber;
        //}
        this.setState({ roundNumber, totalRounds });
    }

    getTickList() {
        let list = [];
        for (let i = 1; i <= this.state.totalRounds; i++) {
            let stopped = true;
            if (i <= this.state.roundNumber) stopped = false;
            list.push(
                <Lottie
                    options={tickCard}
                    height="5vh"
                    isClickToPauseDisabled={true}
                    isStopped={stopped}
                    style={{ marginLeft: "1vh", marginTop: "auto", marginBottom: "auto", }}
                />
            );
        }
        return list;
    }

    setConnected(id, connected) {
        let players = [...this.state.players];
        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.connected = connected;

        players[pos] = player;

        this.setState({ players });
    }

    updateConnectTimer(id, timer) {
        let players = [...this.state.players];
        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.connectingTimer = timer;

        players[pos] = player;

        this.setState({ players });
    }

    doBannerSFX() {
        this.playAudio(bannerSFX, false, 1);
        setTimeout(() => {
            this.playAudio(bannerSFX, false, 1);
        }, 3000);
    }


    doTitleAnim(context) {
        //this.doBannerSFX();
        //let titles = [...this.state.titles];
        //let titleIndex = titles.map(x => x.context).indexOf(context);
        //let title = titles[titleIndex];

        //title.isPaused = false;
        //titles[titleIndex] = title;
        //this.setState({ titles });

        //this.titleRefs[context].play();

        switch (context) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                case 8:
                    this.setState({ playTitle: true, titleText: `ROUND ${context}` });;
                break;
                case "answers":
                    this.setState({ playTitle: true, titleText: "HERE'S WHAT THEY SAID" });
                break;
                case "winners":
                    this.setState({ playTitle: true, titleText: "THE WINNER IS..." });
                break;
            case "tutorial":
                this.setState({ playTitle: true, titleText: "HOW TO PLAY" });
                break;
        }

        setTimeout(() => {
            this.doTitleComplete(context);
        }, 5500);
    }

    doTitleComplete(context) {
        //let titles = [...this.state.titles];
        //let titleIndex = titles.map(x => x.context).indexOf(context);
        //let title = titles[titleIndex];

        //title.isPaused = true;
        //titles[titleIndex] = title;
        //this.setState({ titles });

        //this.titleRefs[context].stop();

        let data = { ...this.state.animData };
        this.setState({ playTitle: false, titleText: "" });

        switch (context) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                this.doNewQuestion(this.state.nextQ, this.state.nextPlayers);
                break;
            case "answers":
                this.showVsPlayersAnswer(data.players, data.vsPlayers);
                break;
            case "winners":
                this.doGameOver(this.state.winners, this.state.nextPlayers);
                break;
            case "tutorial":
                this.setState({ doingTutorial: true, });
                setTimeout(() => {
                    this.gameBgAudio.volume(0.05);
                    this.state.room.send("show_tutorial", {});
                    this.setState({ showTutorial: true, });
                }, 500);
                break;
        }
    }

    startGame = (confirm = false) => {
        let allConnected = true;
        for (const [key, value] of Object.entries(this.state.playerConnections)) {
            if (value === false) allConnected = false;
        }
        console.log("allConnected: " + allConnected);
        console.log("confirm: " + confirm);
        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.playAudio(hoverOrClickSFX, false, 0.6);
            this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
            this.setState({ showStartGame: false, showStartWarning: false, });
        }
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmNewGame = () => {
        this.playAgain(true);
    }

    confirmStartGame = () => {
        this.startGame(true);
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartGame) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    getBigNameFontSize(text) {
        let textLength = text.length;
        let fontSize = "8vh";
        if (textLength <= 3) {
            fontSize = "10vh";
        } else if (textLength <= 6) {
            fontSize = "8vh";
        } else {
            fontSize = "6vh";
        }
        return fontSize;
    }

    isVsPlayer(id) {
        if (this.state.vsPlayers.length > 0) {
            if (id === this.state.vsPlayers[0].id || id === this.state.vsPlayers[1].id) {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
    }
    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                console.log("Host Connection Issue detected");
                this.setState({ connectionIssue: true, });
                LoggingService.streamLog(this.state.logStreamId, "Host Connection Issue Detected at Can of Squirms");
                this.hostLostConnectionBug();
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            const roomId = this.getQueryStringValue("roomId");
            const sessionId = this.getQueryStringValue("sessionId");
            const token = this.getQueryStringValue("token");
            console.log(roomId);
            console.log(token);

            if (this.state.connected == false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        console.log(state.players.size);
                        if (state.host.id != room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });

                        this.setState({ roomState: state, maxVoteCount: state.players.size, logStreamId: state.uniqueId });
                        LoggingService.streamLog(state.uniqueId, `Host Reconnected to Can of Squirms, Reconnection Token: ${room.reconnectionToken}`);

                        if (state.cosData.gameState == GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                            this.setState({ isGame: true, });
                            setTimeout(() => {
                                this.setState({ showStartGame: true, });
                            }, 1000);

                            this.startLocationChecks();
                        } else {
                            room.send("change_game", {});
                        }
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        console.log(state.players.size);
                        this.setState({ roomState: state, maxVoteCount: state.players.size });
                    });

                    room.state.cosData.listen("gameState", (currentValue, previousValue) => {
                        if (currentValue != GameStates.Loading && currentValue != GameStates.EndGame && this.state.showStartWarning) {
                            this.setState({ showStartWarning: false });
                        }
                        this.setState({ serverGameState: currentValue });
                    });

                    room.state.players.onAdd((player, key) => {
                        player.listen("connected", (currentValue, previousValue) => {
                            let playerConnections = { ...this.state.playerConnections };
                            playerConnections[key] = currentValue;
                            this.setState({ playerConnections });
                        });
                    });

                    room.onMessage("location_confirmed", (message) => {
                        console.log("location_confirmed", "received on", room.name, message);
                        this.setState({ gotLocationPing: true, });
                    });

                    //room.onMessage("all_connected", (message) => {
                    //    console.log("all_connected", "received on", room.name, message);
                    //    if (room.state.cosData.gameState == GameStates.Loading) {
                    //        setTimeout(() => {
                    //            this.setState({ isGame: true, });
                    //            for (const [key, value] of Object.entries(message.players)) {
                    //                this.addPlayer(value);
                    //            }
                    //            setTimeout(() => {
                    //                this.setState({ showHeaderFooter: true, roundNumber: 1, totalRounds: this.state.players.length, });
                    //            }, 500);
                    //            setTimeout(() => {
                    //                room.send("start_round", {});
                    //            }, 2000);
                    //        }, 2000);
                    //    }
                    //    if (Object.keys(message.players).length < this.state.playersRequired) {
                    //        room.send("change_game", {});
                    //    }
                    //});
                    room.onMessage("show_players", (message) => {
                        console.log("show_players", "received on", room.name, message);
                        for (const [key, value] of Object.entries(message.players)) {
                            this.addPlayer(value);
                        }
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartGame: false,})
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        if ((room.state.cosData.gameState == GameStates.Loading || room.state.cosData.gameState == GameStates.Idle) && !this.state.gameBegun) {
                            this.gameBgAudio.volume(0.2);
                            this.setState({ showTutorial: false, gameBegun: true, showStartGame: false, });
                            setTimeout(() => {
                                this.setState({ doingTutorial: false, });

                                setTimeout(() => {
                                    this.setState({ showHeaderFooter: true, roundNumber: 1, totalRounds: room.state.cosData.maxRounds, });
                                }, 500);
                                room.send("start_playing", {});
                                setTimeout(() => {
                                    room.send("start_round", {});
                                }, 2000);
                            }, 500);
                        }
                    });
                    room.onMessage("player_leave", (message) => {
                        console.log("player_leave", "received on", room.name, message);
                        this.removePlayer(message.sessionId);
                        //setTimeout(() => {
                        //    if (this.state.gameState == GameStates.Question || this.state.gameState == GameStates.Answers) {
                        //        this.movePotatos(this.state.vsPlayers);
                        //    }
                        //}, 1000);

                    });
                    room.onMessage("player_joined", (message) => {
                        console.log("player_joined", "received on", room.name, message);
                        if (this.state.isGame) {
                            this.playAudio(joinedSFX, false, 0.6);
                            this.addPlayer(message.player);
                            //setTimeout(() => {
                            //    if (this.state.gameState == GameStates.Question || this.state.gameState == GameStates.Answers) {
                            //        this.movePotatos(this.state.vsPlayers);
                            //    }
                            //}, 1000);
                        }
                    });
                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });
                    room.onMessage("update_round_number", (message) => {
                        console.log("update_round_number", "received on", room.name, message);
                        this.updateRoundNums(message.totalRounds, message.roundNumber);
                    });
                    room.onMessage("update_timer", (message) => {
                        console.log("update_timer", "received on", room.name, message);
                        this.updateTimer(message.count);
                    });
                    room.onMessage("all_ready", (message) => {
                        console.log("all_ready", "received on", room.name, message);
                        //this.updateTimer("");
                        this.doStartNextRound(message.vsPlayers);
                    });
                    room.onMessage("new_question", (message) => {
                        console.log("new_question", "received on", room.name, message);
                        if (this.state.gameState != GameStates.Question) {
                            this.updateRoundNums(message.totalRounds, message.roundNumber);
                            this.setState({ nextQ: message.newQ, nextPlayers: message.vsPlayers, })

                            this.doTitleAnim(message.roundNumber);
                        }
                    });
                    room.onMessage("player_answered", (message) => {
                        console.log("player_answered", "received on", room.name, message);
                        if (message.doEffect) {
                            this.playAudio(readySFX, false, 0.6);
                        }
                        this.showPlayerAnswered(message.sessionId);
                    });
                    room.onMessage("do_results", (message) => {
                        console.log("do_results", "received on", room.name, message);
                        if (this.state.gameState != GameStates.Answers) {
                            this.setState({ gameState: GameStates.Answers, })
                            setTimeout(() => {
                                this.doResults(message.players, message.vsPlayers);
                            }, 3000);
                        }
                    });
                    room.onMessage("skip_round", (message) => {
                        console.log("skip_round", "received on", room.name, message);
                        if (this.state.gameState == GameStates.Question) {
                            this.doSkipRound();
                        }
                    });
                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        if (this.state.gameState != GameStates.Results) {
                            this.setState({ gameState: GameStates.Results, winners: message.winners, nextPlayers: message.players,  });
                            setTimeout(() => {
                                this.setState({ isQuestion: false, });
                                this.doTitleAnim("winners");
                            }, 1000);
                        }
                    });
                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.resetGame(message.players);
                    });
                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                        this.setState({ room: null });
                    });
                    room.onMessage("set_player_connected", (message) => {
                        console.log("set_player_connected", "received on", room.name, message);
                        this.setConnected(message.id, message.connected);
                    });
                    room.onMessage("update_connect_timer", (message) => {
                        console.log("update_connect_timer", "received on", room.name, message);
                        this.updateConnectTimer(message.id, message.timer);
                    });
                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showStartGame: false, });
                        setTimeout(() => {
                            this.doTitleAnim("tutorial");
                        }, 3000);
                    });
                    room.onMessage("update_skip_vote", (message) => {
                        console.log("update_skip_vote", "received on", room.name, message);
                        this.setState({ skipTutorialCount: message.voteCount });
                    });
                    room.onError((code, message) => {
                        console.log(this.client.id, "couldn't join", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host OnError at Can of Squirms, code: ${code} Message: ${JSON.stringify(message)}`);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host Left Can of Squirms, Code: ${code}`);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Squirms.";
                    if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Host OnJoinError at Can of Squirms: ${JSON.stringify(e)}`);
                    //LoggingService.logError(message, e);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}` })
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        }
    }

    render() {

        //return (
        //    <div>
        //        <Loading loadingText={"Sending you to the lobby!"} />
        //    </div>
        //)

        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        {/*<Lottie options={bgAnim} width="100%" height="100%" isClickToPauseDisabled={true} style={{ position: 'absolute', }} />*/}
                        <img src={bgAnimGif} className={styles.background} />
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <div id="gameContainer" className={styles.gameContainer}>
                    {/*<Lottie options={bgAnim} width="100%" height="100%" isClickToPauseDisabled={true} style={{ position: 'absolute', }} />*/}
                    <img src={bgAnimGif} className={styles.background} />
                    {/*{*/}
                    {/*    this.state.doConfetti &&*/}
                    {/*    <Confetti*/}
                    {/*        width={window.innerWidth}*/}
                    {/*        height={window.innerHeight}*/}
                    {/*        initialVelocityY={20}*/}
                    {/*        numberOfPieces={500}*/}
                    {/*        recycle={false}*/}
                    {/*        confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}*/}
                    {/*        initialVelocityY={{ min: -10, max: -30, }}*/}
                    {/*        initialVelocityX={{ min: -10, max: 10, }}*/}
                    {/*        onConfettiComplete={() => this.setState({ doConfetti: false, })}*/}
                    {/*    />*/}
                    {/*}*/}
                    {
                        !this.state.isGame ?
                            <div id="logoSection" className={styles.logo}></div>
                            :

                            <React.Fragment>
                                <Menu room={this.state.room} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} toggleMute={this.toggleMute} muted={this.state.muted} />
                                {
                                    this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.serverGameState) &&
                                    <ErrorModal
                                        title={"Are you ready to play?"}
                                        styles={"d-flex"}
                                        message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                                        callback={this.closeStartWarning}
                                        callbackText={"No"}
                                        callback2={this.state.showStartGame ? this.confirmStartGame : this.confirmNewGame}
                                        callbackText2={"Yes"}
                                    />
                                }
                                {
                                    this.state.doingTutorial ?
                                        <Tutorial show={this.state.showTutorial} skipCount={this.state.skipTutorialCount} playerCount={this.state.maxVoteCount} room={this.state.room} />
                                        :
                                        null
                                }
                                <div id="logoSection" className={styles.logo}></div>
                                <div id="timerSection" className={`${styles.timerSection} ${this.state.showTimer ? "" : styles.hideTimer}`}>
                                    <div className={styles.timerBg}></div>
                                    <Lottie options={this.state.timerOptions} width="15vh" height="15vh" isClickToPauseDisabled={true} />
                                    <div id="timerText" className={styles.timerText}>{this.state.timer}</div>
                                </div>
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div id="headerSection" className={styles.headerSection}>
                                    <div className={`${styles.counterSection} ${this.state.showHeaderFooter ? "" : styles.hidden}`}>
                                        <div className={styles.counterText}>{`${this.state.roundNumber}/${this.state.totalRounds}`}</div>
                                        <div className={styles.tickList}>
                                            {
                                                this.getTickList()
                                            }
                                        </div>
                                    </div>
                                    <div id="questionBox" className={`${styles.questionBox} ${this.state.showQuestion ? "" : styles.hidden} ${this.state.fadeOutContent ? styles.vanish : ""} ${this.state.shrinkQuestion ? styles.shrunk : ""}`}>
                                        {
                                            this.state.isQuestion ?
                                                <div id="questionText" className={styles.questionText} style={{ fontSize: this.state.qSize, }}>{this.state.question.question}</div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                {/*{*/}
                                {/*    this.state.titles.map((title, index) => {*/}
                                {/*        return <Lottie*/}
                                {/*            options={title.options}*/}
                                {/*            width="100%"*/}
                                {/*            height="100%"*/}
                                {/*            isClickToPauseDisabled={true}*/}
                                {/*            style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 20, touchAction: "none", userSelect: "none", opacity: title.isPaused ? 0 : 1 }}*/}
                                {/*            eventListeners={[*/}
                                {/*                {*/}
                                {/*                    eventName: 'complete',*/}
                                {/*                    callback: () => this.doTitleComplete(title.context),*/}
                                {/*                },*/}
                                {/*            ]}*/}
                                {/*            isPaused={title.isPaused}*/}
                                {/*            isStopped={title.isPaused}*/}
                                {/*            ref={animation => {*/}
                                {/*                this.titleRefs[title.context] = animation;*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    })*/}
                                {/*}*/}
                                <div className={`${styles.bannerContainer} ${this.state.playTitle && styles.play}`}>
                                    <img className={styles.bannerBg} src={bannerImg} />
                                    <div className={styles.bannerInfo}>
                                        <div className={styles.bannerTitle}>{this.state.titleText}</div>
                                    </div>
                                </div>
                                {
                                    this.state.isQuestion ?
                                        <div id="vsSection" className={styles.vsSection}>
                                            <div className={styles.vsPlayerSection}>
                                                <div className={`${styles.flipCard} ${styles.left} ${this.state.showPlayerOne ? "" : styles.hidden} ${this.state.fadeOutContent ? styles.vanish : ""}`}>
                                                    <div className={`${styles.flipCardInner} ${this.state.showAnswers ? styles.doFlip : ""}`}>
                                                        <div className={`${styles.cardFront} ${styles.playerBox}`}>
                                                            <img src={squirmsLogo} className={styles.boxLogo} />
                                                            {/*<div className={`${styles.playerText}`}>{this.state.vsPlayers[0] ? this.state.vsPlayers[0].name : ""} </div>*/}
                                                        </div>
                                                        <div className={`${styles.cardBack} ${styles.playerBox}`}>
                                                            {/*<div className={`${styles.choseText}`}>{this.state.showAnswers ? `${this.state.vsPlayers[0].name} Picked:` : ""}</div>*/}
                                                            <div className={`${styles.playerText}`} style={this.state.showAnswers ? { fontSize: this.getBigNameFontSize(this.getNamebyId(this.state.vsPlayers[0].cosData.answer))} : {}}>{this.state.showAnswers ? `${this.getNamebyId(this.state.vsPlayers[0].cosData.answer)}` : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${styles.notesBubble} ${styles.one} ${this.state.showAnswers ? this.state.vsPlayers[0].cosData.notes ? styles.show : null : null}`}>
                                                    <div className={`${styles.cornerTick} ${styles.left}`} />
                                                    <div className={styles.notesText}>{this.state.showAnswers ? `${this.state.vsPlayers[0].cosData.notes}` : ""}</div>
                                                </div>
                                                <div id="anchorOne_first" className={`${styles.playerAnchor} ${styles.one} ${styles.first}`}></div>
                                                <div id="anchorOne_second" className={styles.playerAnchor + " " + styles.one}></div>
                                                {/*<div className={`${styles.bigName} ${styles.left} ${this.state.shrinkPotatos ? styles.smaller : ""} ${this.state.showBigNames ? styles.show : ""}`} style={this.state.vsPlayers[0] ? { fontSize: this.getBigNameFontSize(this.state.vsPlayers[0].name) } : {}}>{this.state.vsPlayers[0] ? this.state.vsPlayers[0].name : ""}</div>*/}
                                                {
                                                    this.state.validateVs ?
                                                        <Lottie options={plusTwo} height="18vh" isClickToPauseDisabled={true} style={{ position: 'absolute', zIndex: 10, right: "15vh", }} />
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div id="vsBox" className={`${styles.vsBox} ${this.state.showVs ? styles.show : ""} ${this.state.fadeOutContent ? styles.vanish : ""}`}>
                                                <img src={vsImg} className={styles.vsImg} />
                                            </div>
                                            <div className={styles.vsPlayerSection}>
                                                {/*<div className={`${styles.playerBox} ${styles.right} ${this.state.showPlayerTwo ? "" : styles.hidden} ${this.state.fadeOutContent ? styles.vanish : ""}`}>*/}
                                                {/*    <div className={`${styles.playerText} ${this.state.showAnswers ? styles.hide : ""}`}>{this.state.vsPlayers[1] ? this.state.vsPlayers[1].name : ""}</div>*/}
                                                {/*    <div className={`${styles.choseText} ${this.state.showAnswers ? "" : styles.hide}`}>{this.state.showAnswers ? `${this.state.vsPlayers[1].name} Picked:` : ""}</div>*/}
                                                {/*    <div className={`${styles.playerText} ${styles.lower} ${this.state.showAnswers ? "" : styles.hide}`}>{this.state.showAnswers ? `${this.getNamebyId(this.state.vsPlayers[1].cosData.answer)}` : ""}</div>*/}
                                                {/*</div>*/}
                                                <div className={`${styles.flipCard} ${styles.right} ${this.state.showPlayerTwo ? "" : styles.hidden} ${this.state.fadeOutContent ? styles.vanish : ""}`}>
                                                    <div className={`${styles.flipCardInner} ${this.state.showAnswers ? styles.doFlip : ""}`}>
                                                        <div className={`${styles.cardFront} ${styles.playerBox}`}>
                                                            <img src={squirmsLogo} className={styles.boxLogo} />
                                                            {/*<div className={`${styles.playerText}`}>{this.state.vsPlayers[1] ? this.state.vsPlayers[1].name : ""} </div>*/}
                                                        </div>
                                                        <div className={`${styles.cardBack} ${styles.playerBox}`}>
                                                            {/*<div className={`${styles.choseText}`}>{this.state.showAnswers ? `${this.state.vsPlayers[1].name} Picked:` : ""}</div>*/}
                                                            <div className={`${styles.playerText}`} style={this.state.showAnswers ? { fontSize: this.getBigNameFontSize(this.getNamebyId(this.state.vsPlayers[0].cosData.answer)) } : {}}>{this.state.showAnswers ? `${this.getNamebyId(this.state.vsPlayers[1].cosData.answer)}` : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${styles.notesBubble} ${styles.two} ${this.state.showAnswers ? this.state.vsPlayers[1].cosData.notes ? styles.show : null : null}`}>
                                                    <div className={`${styles.cornerTick} ${styles.right}`} />
                                                    <div className={styles.notesText}>{this.state.showAnswers ? `${this.state.vsPlayers[1].cosData.notes}` : ""}</div>
                                                </div>
                                                <div id="anchorTwo_first" className={`${styles.playerAnchor} ${styles.two} ${styles.first}`}></div>
                                                <div id="anchorTwo_second" className={styles.playerAnchor + " " + styles.two}></div>
                                                {/*<div className={`${styles.bigName} ${styles.right} ${this.state.shrinkPotatos ? styles.smaller : ""} ${this.state.showBigNames ? styles.show : ""}`} style={this.state.vsPlayers[1] ? { fontSize: this.getBigNameFontSize(this.state.vsPlayers[1].name) } : {} }>{this.state.vsPlayers[1] ? this.state.vsPlayers[1].name : ""}</div>*/}
                                                {
                                                    this.state.validateVs ?
                                                        <Lottie options={plusTwo} height="18vh" isClickToPauseDisabled={true} style={{ position: 'absolute', zIndex: 10, left: "15vh", }} />
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div id="vsSection" className={styles.vsSection}></div>
                                }
                                <div id="playersSection" className={`${styles.playersSection} ${this.state.showHeaderFooter ? "" : styles.hidden} ${this.state.hideNames ? styles.hideNames : ""}`}>
                                    {
                                        this.state.players.map((player) => {
                                            return <Player player={player} doPodium={this.state.doPodiums} isVsPlayer={this.isVsPlayer(player.id)} vsPlayers={this.state.vsPlayers} movePosOne={this.state.movePosOne} movePosTwo={this.state.movePosTwo} movePotOne={this.state.movePotOne} movePotTwo={this.state.movePotTwo} showAnswers={this.state.shrinkPotatos} />
                                        })
                                    }
                                </div>
                                <div id="winnerBox" className={`${styles.winnerBox} ${this.state.showWinners ? styles.show : ""}`}>
                                    <div id="winnerText" className={`${styles.winnerText} ${styles.smaller}`}>{this.state.winnerTitle}</div>
                                    <div id="winnerText" className={styles.winnerText}>{this.state.winnerText}</div>
                                </div>
                                <div id="playAgainSection" className={`${styles.playAgainSection} ${this.state.playAgain ? styles.show : ""}`}>
                                    <div className={styles.buttonsSection}>
                                        <button id="playAgainButton" className={`${styles.choiceButton}`} onClick={() => this.playAgain()}>Play Again</button>
                                        <button id="differentGameButton" className={`${styles.choiceButton} ${styles.smaller}`} onClick={() => this.differentGame()}>Different Game</button>
                                    </div>
                                    <div className={styles.leaderboardSection}>
                                        {
                                            this.state.leaderboardPlayers.map((x, index) => {
                                                return <div className={styles.boardRow}>
                                                    <div className={styles.numberBox}>
                                                        <div className={styles.positionText}>{x.position}</div>
                                                    </div>
                                                    <div className={styles.nameBox}>{x.name}</div>
                                                    <div className={styles.scoreBox}>
                                                        <div className={styles.text}>{x.score}</div>
                                                        <div className={`${styles.text} ${styles.smaller}`}>PTS</div>
                                                    </div>
                                                    <div className={styles.potato}>
                                                        <Lottie
                                                            options={getAvatarById(x.avatar).idleAnim}
                                                            width="100%"
                                                            height="100%"
                                                            isClickToPauseDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div id="startGameSection" className={`${styles.startGameSection} ${styles.center} ${this.state.showStartGame ? styles.show : ""}`}>
                                    <button id="startGameButton" className={`${styles.choiceButton}`} onClick={() => this.startGame()}>Start Game</button>
                                    <button id="differentGameButton" className={`${styles.choiceButton} ${styles.smaller}`} onClick={() => this.differentGame()}>Go To Lobby</button>
                                    <div className={styles.skipBox}>
                                        <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                        <label for="checkbox">Skip Tutorial</label>
                                    </div>
                                </div>
                                {/*<div style={{ position: "absolute", display: "flex", flexDirection: "column", zIndex: 10000 }}>*/}
                                {/*    {*/}
                                {/*        this.state.titles.map((title) => {*/}
                                {/*            return <button onClick={() => this.doTitleAnim(title.context)}>{title.context} Title</button>*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*</div>*/}
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}
